import { render, staticRenderFns } from "./EditOriginationModal.vue?vue&type=template&id=cfe2713a&scoped=true&"
import script from "./EditOriginationModal.vue?vue&type=script&lang=js&"
export * from "./EditOriginationModal.vue?vue&type=script&lang=js&"
import style0 from "./EditOriginationModal.vue?vue&type=style&index=0&id=cfe2713a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfe2713a",
  null
  
)

export default component.exports