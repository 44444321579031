<template>
  <div class="modal" id="editOrigination">
    <div class="modal-bg" @click="$emit('close')"></div>

    <ModalSpinner v-show="ui.loan.loading === true"></ModalSpinner>

    <div id="edit-origination-form-modal-block" class="modal-block" v-show="ui.loan.loading === false">
      <div class="modal-header">
        <h3>{{ loan.isReferral ? 'Edit Referral Application' : 'Edit Application' }}</h3>
      </div>
      <form @submit="submitForm">
        <div class="modal-body">
          <div class="modal-body-container borrower-selected cursor-pointer">
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <v-avatar :username="loan.borrower.firstName + ' ' + loan.borrower.lastName" :size="42" rounded/>
                <div class="borrower-info">
                  <div class="borrower-from">From LendingPad</div>
                  <div>
                    <span class="borrower-name">{{ loan.borrower.firstName }} {{ loan.borrower.lastName }}</span>
                    <span class="borrower-conventional">{{ loan.borrower.product }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-body-container">
            <div class="form-group full-width mb-2" v-if="loan.isReferral">
              <label>What state is the property located in?</label>
              <multiselect id="fieldStateLocation" v-model="loan.state"
                           :allow-empty="false"
                           :close-on-select="true"
                           :options="formData.states"
                           :searchable="true"
                           :show-labels="false"
                           @select="selectState"
                           class="full-width selected" label="label" track-by="value" placeholder="Select state">
              </multiselect>
            </div>
            <div class="form-group full-width mb-3" v-if="loan.isReferral">
              <label for="LO">Loan Officer of Record</label>
              <multiselect id="LO" v-model="loan.LOofRecord"
                           :allow-empty="false"
                           :class="{selected: loan.LOofRecord !== null}"
                           :close-on-select="true"
                           :options="loan.state.agents"
                           :searchable="true"
                           :show-labels="false"
                           track-by="value" label="label" class="full-width" placeholder="Select agent">
              </multiselect>
            </div>

            <div class="form-group full-width">
              <label v-tippy="{ placement : 'right',  arrow: true }"
                     content="Depends on workflow you will need to fill out different fields"
                     aria-label="fieldWorkflow">Selected Workflow</label>
              <multiselect id="fieldWorkflow"
                           v-model="ui.selectedWorkflow"
                           :allow-empty="false"
                           :close-on-select="true"
                           :options="formData.workflows"
                           :searchable="false"
                           :show-labels="false"
                           @select="toggleProcessors"
                           class="full-width selected" track-by="value" label="label" placeholder="Select workflow">
              </multiselect>
            </div>
          </div>

          <div class="modal-body-container">
            <div class="form-group mb-3 full-width">
              <label for="fieldWaivedStatus">Impounds/Escrows?</label>
              <multiselect id="fieldWaivedStatus" v-model="loan.waivedStatus"
                           class="full-width"
                           :class="{selected: loan.waivedStatus !== null}"
                           :options="formData.waivedStatuses"
                           :searchable="false"
                           :close-on-select="true"
                           :allow-empty="false"
                           :show-labels="false"
                           placeholder="Impounds/Escrows?">
              </multiselect>
            </div>

            <div class="form-group mb-3">
              <label for="fieldLenderType">Broker or Correspondent?</label>
              <multiselect id="fieldLenderType" v-model="loan.lenderType"
                           :options="formData.lenderTypes"
                           :class="{selected: loan.lenderType !== null}"
                           :searchable="false"
                           :close-on-select="true"
                           :allow-empty="false"
                           :show-labels="false" @select="checkLenderType" placeholder="Lender Type">
              </multiselect>
            </div>

            <div class="form-group full-width mb-3">
              <label for="fieldLender">Lender</label>
              <multiselect id="fieldLender" v-model="loan.lender"
                           :class="{selected: loan.lender !== null}"
                           :disabled="loan.lenderType === 'Correspondent'"
                           :options="formData.lenders"
                           :searchable="true"
                           :close-on-select="true"
                           :allow-empty="false"
                           :show-labels="false"
                           @select="checkLenderTags"
                           class="full-width" track-by="value" label="label" placeholder="Select Lender">
              </multiselect>
            </div>

            <div class="form-group full-width mb-3" v-if="loan.enhancedProcessing">
              <label aria-label="fieldPreferredTitleCompany">Preferred Title Company</label>
              <multiselect id="fieldPreferredTitleCompany" v-model="loan.preferredTitleCompany"
                           :allow-empty="false"
                           :class="{selected: loan.preferredTitleCompany !== null}"
                           :close-on-select="true"
                           :options="formData.companies"
                           :searchable="true"
                           :show-labels="false"
                           class="full-width" label="label" placeholder="Select Preferred Title Company"
                           track-by="value">
              </multiselect>
            </div>

            <div class="form-group mb-3">
              <label for="fieldAus">AUS/DU/LP</label>
              <multiselect id="fieldAus" v-model="loan.aus"
                           :class="{selected: loan.ausOptions !== null}"
                           :options="formData.ausOptions"
                           :searchable="false"
                           :close-on-select="true"
                           :allow-empty="false"
                           :show-labels="false"
                           placeholder="AUS/DU/LP">
              </multiselect>
            </div>
          </div>
          <div class="modal-body-container">
            <div class="row" v-if="loan.lenderType === 'Broker'">
              <div class="col-md-6 form-group">
                <label for="fieldAntiSteeringLowestRateAndFee">Anti Steering: Lowest Rate and Fee</label>
                <input id="fieldAntiSteeringLowestRateAndFee" type="text" class="form-control"
                       v-model="loan.antiSteeringLowestRateAndFee"
                       :class="{'input-filled': loan.antiSteeringLowestRateAndFee !== null}">
              </div>
              <div class="col-md-6 form-group">
                <label for="fieldAntiSteeringHighestRateAndFee">Anti Steering: Highest Rate and Fee</label>
                <input id="fieldAntiSteeringHighestRateAndFee" type="text" class="form-control"
                       v-model="loan.antiSteeringHighestRateAndFee"
                       :class="{'input-filled': loan.antiSteeringHighestRateAndFee !== null}">
              </div>
            </div>
            <div class="form-group full-width mb-3 mt-3">
              <div class="d-flex justify-content-start mt-3 mb-2" v-if="loan.lenderType === 'Correspondent'">
                <button class="btn btn-outline-dark" type="button" @click="ui.showCorrPricerModal = true">
                  Select Pricing
                </button>
              </div>
              <div class="d-flex w-100 justify-content-start align-items-center mb-2">
                <label for="fieldOtherNotes" class="mb-0">Note</label>
              </div>
              <textarea id="fieldOtherNotes" v-model="loan.feesForBorrower" rows="4" class="form-control"
                        :class="{'input-filled': loan.feesForBorrower !== null}"
                        placeholder="Put note in here to remind yourself exactly what fees you promised on this file"></textarea>
            </div>
            <div class="form-group full-width mb-3">
              <label for="fieldBorrowerMotivation">Borrower Motivation</label>
              <textarea id="fieldBorrowerMotivation" v-model="loan.otherNotes" rows="4" class="form-control"
                        :class="{'input-filled': loan.otherNotes !== null}"
                        placeholder="Borrower motivation / What's the plan? / Anything else we should know?"></textarea>
            </div>
          </div>
          <div class="modal-body-container">
            <div class="col-md-10 mb-4">
              <label for="fieldProcessor">Processor</label>
              <multiselect id="fieldProcessor" v-model="loan.processor" placeholder="Select Processor"
                           track-by="value" label="label"
                           :class="{selected: loan.processors !== null}"
                           class="full-width"
                           :options="formData.processorOptions"
                           :searchable="true"
                           :close-on-select="true"
                           :allow-empty="false"
                           :show-labels="false">
              </multiselect>
              <div class="processor-details" v-if="loan.processor && loan.processor.value !== $store.getters.getUserId">
                <img :src="agentPhoto(loan.processor.photoUrl)" alt="">
                <div>
                  <b>{{ loan.processor.name }}</b> <br>
                  {{ formatUSNumber(loan.processor.phone) }} / {{ loan.processor.email }}
                </div>
              </div>
            </div>
            <div class="form-group col-md-6 mb-4" v-if="loan.enhancedProcessing">
              <label aria-label="fieldProcessor">Vesting</label>
              <multiselect id="fieldVestingType" v-model="loan.vesting"
                           :allow-empty="false"
                           :class="{selected: loan.vesting !== null}"
                           :close-on-select="true"
                           :options="formData.vestingOptions"
                           :searchable="false"
                           :show-labels="false">
              </multiselect>
            </div>
            <div class="form-group full-width mb-4" v-if="loan.enhancedProcessing">
              <label aria-label="fieldProcessor">
                How is title held?
                <img src="@/assets/icons/icon-info.svg" alt="preview" v-tippy="{ placement : 'right',  arrow: true }"
                     content="This field is important to prevent issues at closing. If there is no change to title, copy and paste it from title profile.">
              </label>
              <textarea id="fieldLoanTitle"
                        v-model="loan.title"
                        :class="{'input-filled': loan.title !== null}"
                        class="form-control" rows="4">
              </textarea>
            </div>
            <div v-if="loan.enhancedProcessing">
              <label>Is there a non-borrowing spouse?</label>
              <div class="mb-3">
                <div class="d-flex">
                  <span :class="{ active: loan.isNonBorrowingSpouse }" class="check-btn"
                        @click="loan.isNonBorrowingSpouse = true">Yes</span>
                  <span :class="{ active: !loan.isNonBorrowingSpouse }" class="check-btn"
                        @click="loan.isNonBorrowingSpouse = false">No</span>
                </div>
              </div>
            </div>
            <div class="form-group full-width mb-3">
              <label>Stips</label>
              <file-field-new :multiple="true" :files="stips.uploaded" @change="submitStips"
                              field-id="edit-origination-stips"/>

              <ul class="list-group mt-3" v-if="stips.received.length > 0">
                <li v-for="stip in stips.received" :key="stip.id"
                    class="list-group-item d-flex justify-content-between align-items-center">
                  {{ stip.clientFilename }}
                  <div class="list-group-item-icons d-flex">
                  <span
                    class="badge cursor-pointer btn btn-control"
                    @click="openFilePreview('stip', stip.id, stip.clientFilename)">
                    <img src="@/assets/icons/icon-preview.svg" alt="preview">
                  </span>
                    <span class="badge cursor-pointer btn btn-control" @click="deleteStip(stip.id)">
                    <img src="@/assets/icons/icon-delete.svg" alt="delete">
                  </span>
                  </div>
                </li>
              </ul>

            </div>
          </div>

          <div class="modal-body-container mt-4">
            <div class="modal-body-container_title">Borrower</div>
            <div class="row mb-4">
              <div class="form-group col-md-6">
                <label for="fieldFirstName">First Name</label>
                <input v-model="loan.borrower.firstName"
                       :class="{'input-filled': loan.borrower.firstName !== null}"
                       type="text" class="form-control" id="fieldFirstName" placeholder="First Name">
              </div>
              <div class="form-group col-md-6">
                <label for="fieldLastName">Last Name</label>
                <input v-model="loan.borrower.lastName"
                       :class="{'input-filled': loan.borrower.lastName !== null}"
                       type="text" class="form-control" id="fieldLastName" placeholder="Last Name">
              </div>
            </div>
            <div class="row mb-4">
              <div class="form-group col-md-12 w-76">
                <label for="fieldEmail">Email</label>
                <input id="fieldEmail" v-model="loan.borrower.email"
                       :class="{'input-filled': loan.borrower.email !== null}"
                       class="form-control mb-2" placeholder="Email" type="text">
                <span class="pt-3">Use '/' or ',' to separate emails: <i>example@mail.com / example@mail.com</i></span>
              </div>
            </div>
            <div class="row mb-4">
              <div class="form-group col-md-6">
                <label for="fieldCoFirstName">Co First Name</label>
                <input v-model="loan.borrower.coFirstName"
                       :class="{'input-filled': loan.borrower.coFirstName !== null}"
                       type="text" class="form-control" id="fieldCoFirstName" placeholder="First Name (Optional)">
              </div>
              <div class="form-group col-md-6">
                <label for="fieldCoLastName">Co Last Name</label>
                <input v-model="loan.borrower.coLastName"
                       :class="{'input-filled': loan.borrower.coLastName !== null}"
                       type="text" class="form-control" id="fieldCoLastName" placeholder="Last Name (Optional)">
              </div>
            </div>
            <div class="row mb-4">
              <div class="form-group col-md-12 w-76">
                <label for="fieldEmail">Co Email</label>
                <input id="fieldEmail" v-model="loan.borrower.coEmail"
                       :class="{ 'input-filled': loan.borrower.coEmail !== null }"
                       class="form-control" placeholder="Email" type="text"/>
                <span class="pt-3">Use '/' or ',' to separate emails:
                    <i>example@mail.com / example@mail.com</i>
                  </span>
              </div>
            </div>
            <div class="row mb-4">
              <div class="form-group col-md-6">
                <label for="fieldCreditScore">Credit Score</label>
                <input v-model.number="loan.borrower.creditScore"
                       :class="{'input-filled': loan.borrower.creditScore !== null}"
                       type="number" class="form-control" id="fieldCreditScore" placeholder="740">
              </div>
            </div>
            <div class="row mb-4" v-if="!loan.isReferral">
              <div class="form-group col-md-6">
                <label for="fieldPropertyState">Property State</label>
                <multiselect v-model="loan.propertyState"
                             placeholder="Select state"
                             id="fieldPropertyState"
                             :options="formData.states"
                             :class="{selected: loan.propertyState !== null}"
                             :searchable="true"
                             :close-on-select="true"
                             :allow-empty="false"
                             :show-labels="false">
                </multiselect>
              </div>
              <div class="form-group col-md-6">
                <label aria-label="fieldPropertyState">Property Zip</label>
                <input id="fieldZipCode" type="text" class="form-control" placeholder="Zip Code"
                       v-model="loan.zip"
                       :class="{ 'input-filled': loan.zip !== null }"/>
              </div>
            </div>

          </div>
          <div class="modal-body-container mb-0 pb-0 border-bottom-0">
            <div class="modal-body-container_title">Loan Details</div>
            <div>
              <label>Loan Purpose</label>
              <div class="mb-3">
                <div class="d-flex">
                  <span :class="{ active: loan.purpose === 'Refinance' }" class="check-btn"
                        @click="loan.purpose = 'Refinance'">Refinance</span>
                  <span :class="{ active: loan.purpose === 'Purchase' }" class="check-btn"
                        @click="loan.purpose = 'Purchase'">Purchase</span>
                  <span :class="{ active: loan.purpose === 'Home improvement' }" class="check-btn"
                        @click="loan.purpose = 'Home improvement'">Home improvement</span>
                </div>
              </div>
            </div>

            <div class="mb-3 mt-3" v-if="loan.purpose === 'Purchase'">
              <button class="btn bg-green px-4" type="button" @click="ui.showAssignRealtorsModal = true" v-if="loan.purpose === 'Purchase'">
                Assign Realtors
              </button>

              <div class="form-group mt-3" v-if="loan.listingAgents && loan.listingAgents.length > 0">
                <label for="">Listing Agents</label>
              </div>

              <div v-if="loan.listingAgents && loan.listingAgents.length > 0">
                <div class="processor-details mt-1 justify-content-between" v-for="agent in loan.listingAgents" :key="'listing' + agent.id">
                  <div>
                    <b>{{ agent.fullName }} {{ agent.isTC ? "(TC)" : '' }}</b> <br>
                    {{ formatUSNumber(agent.phone) }} / {{ agent.email }}
                  </div>
                  <div>
                    <button class="btn btn-control" @click="removeListingAgent(agent.id)">
                      <img class="m-0" src="@/assets/icons/icon-remove.svg" alt="">
                    </button>
                  </div>
                </div>
              </div>

              <div class="form-group mt-3" v-if="loan.buyingAgents && loan.buyingAgents.length > 0">
                <label for="">Buying Agents</label>
              </div>

              <div v-if="loan.buyingAgents && loan.buyingAgents.length > 0">
                <div class="processor-details mt-1 justify-content-between" v-for="agent in loan.buyingAgents" :key="'buying' + agent.id">
                  <div>
                    <b>{{ agent.fullName }} {{ agent.isTC ? "(TC)" : '' }}</b> <br>
                    {{ formatUSNumber(agent.phone) }} / {{ agent.email }}
                  </div>
                  <div>
                    <button class="btn btn-control" @click="removeBuyingAgent(agent.id)">
                      <img class="m-0" src="@/assets/icons/icon-remove.svg" alt="">
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- work1 -->
            <div v-if="loan.purpose === 'Purchase'" class="form-group full-width other-options mb-3">
              <div class="form-check">
                <input id="isFirstTimeHomeBuyer" class="form-check-input" type="checkbox" v-model="loan.isFirstTimeHomeBuyer" />
                <label class="form-check-label" for="isFirstTimeHomeBuyer">First Time Homebuyer</label>
              </div>
            </div>
            <div class="form-group full-width other-options mb-3">
              <div class="form-check">
                <input id="addHOA" class="form-check-input" type="checkbox" v-model="ui.form.addHOA" />
                <label class="form-check-label" for="addHOA">Add HOA Information</label>
              </div>
              <template v-if="ui.form.addHOA">
                <input
                  v-model="loan.hoaName"
                  :class="{ 'input-filled': loan.hoaName !== null }"
                  class="form-control mb-3"
                  type="text"
                  placeholder="Name"
                />
                <input
                  v-model="loan.hoaPhone"
                  :class="{ 'input-filled': loan.hoaPhone !== null }"
                  class="form-control mb-3"
                  type="text"
                  v-mask="'(###) ###-####'"
                  placeholder="Phone Number"
                />
                <input
                  v-model="loan.hoaEmail"
                  :class="{ 'input-filled': loan.hoaEmail !== null }"
                  class="form-control mb-3"
                  type="email"
                  placeholder="Email"
                />
              </template>
            </div>

            <div class="form-group full-width mb-3" v-if="loan.purpose === 'Refinance'">
              <label for="fieldPayingOffMortgagesNumber">How many mortgages are we paying off on this property?</label>
              <input id="fieldPayingOffMortgagesNumber"
                    v-model.number="loan.payingOffMortgagesNumber"
                    :class="{'input-filled': loan.payingOffMortgagesNumber !== null}"
                    class="form-control small-width"
                    placeholder="1" type="number">
            </div>

            <div class="row mb-4">
              <div class="form-group col-md-6">
                <label for="fieldLoanProduct">Product</label>
                <multiselect v-model="loan.productType"
                            placeholder="Select product"
                            id="fieldLoanProduct"
                            :class="{selected: loan.productType !== null}"
                            :options="formData.loanTypes"
                            :searchable="false"
                            :close-on-select="true"
                            :allow-empty="false"
                            :show-labels="false">
                </multiselect>
              </div>
              <div class="form-group col-md-6">
                <label for="fieldProductAndTerm">Product and Term</label>
                <input v-model="loan.productAndTerm"
                      :class="{'input-filled': loan.productAndTerm !== null}"
                      class="form-control" id="fieldProductAndTerm" type="text" placeholder="30y fixed">
              </div>
            </div>
            <div v-if="ui.selectedWorkflow.value === 'processor' && loan.productType === 'VA'" class="row mb-4">
              <div class="form-group full-width mb-3">
                <label aria-label="fieldVaType">VA Type</label>
                <multiselect id="fieldVaType" v-model="loan.vaType"
                             :allow-empty="false"
                             :class="{ selected: loan.vaType !== null }"
                             :close-on-select="true"
                             :options="formData.vaTypes"
                             :searchable="false"
                             :show-labels="false"
                             placeholder="VA Type">
                </multiselect>
              </div>
              <div class="form-group full-width mb-3">
                <label aria-label="fieldVaUseType">VA Use Type</label>
                <multiselect id="fieldVaUseType" v-model="loan.vaUseType"
                             :allow-empty="false"
                             :class="{ selected: loan.vaUseType !== null }"
                             :close-on-select="true"
                             :options="formData.vaUseTypes"
                             :searchable="false"
                             :show-labels="false"
                             placeholder="VA Use Type">
                </multiselect>
              </div>
            </div>
            <div class="form-group full-width mb-3" v-if="loan.productType === 'VA'">
              <label for="fieldVaExempt">VA EXEMPT?</label>
              <multiselect v-model="loan.vaExempt"
                          placeholder="VA Exempt"
                          id="fieldVaExempt"
                          track-by="value"
                          label="label"
                          :class="{selected: loan.vaExempt !== null}"
                          :options="formData.vaExemptOptions"
                          :searchable="false"
                          :close-on-select="true"
                          :allow-empty="false"
                          :show-labels="false">
              </multiselect>
              <div class="form-text">(borrower >10% disabled) - THIS IS IMPORTANT IN PRODUCT SELECTION</div>
            </div>
            <div class="row mb-3">
              <div class="form-group col-md-6">
                <label for="fieldLoanAmount">Loan Amount</label>
                <div class="input-with-icon mb-2">
                  <span class="icon">$</span>
                  <input id="fieldLoanAmount"
                        v-model.number="loan.amount"
                        :class="{'input-filled': loan.amount !== null}"
                        class="form-control"
                        type="number"
                        step="0.01">
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="fieldLoanAmount">Property value</label>
                <div class="input-with-icon mb-2">
                  <span class="icon">$</span>
                  <input id="fieldLoanAmount"
                         v-model.number="loan.propertyValue"
                         :class="{'input-filled': loan.propertyValue !== null}"
                         class="form-control"
                         type="number"
                         step="0.01">
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="fieldLoanNumber">Loan Number</label>
                <input v-model.number="loan.number"
                      :class="{'input-filled': loan.number !== null}"
                      class="form-control" id="fieldLoanNumber" type="number" :placeholder="loanNumberPlaceHolder">
              </div>
            </div>
            <div class="row mb-3">
              <div class="form-group col-md-6">
                <label aria-label="fieldOccupancy">Occupancy</label>
                <multiselect id="fieldOccupancy" v-model="loan.occupancy"
                            :allow-empty="false"
                            :class="{ selected: loan.occupancy !== null }"
                            :close-on-select="true"
                            :options="formData.occupancies"
                            :searchable="false"
                            :show-labels="false"
                            placeholder="Select occupancy">
                </multiselect>
              </div>
              <div class="form-group col-md-6">
                <label for="fieldNumberOfUnits">Number Of Units</label>
                <input id="fieldNumberOfUnits" v-model.number="loan.numberOfUnits"
                      :class="{ 'input-filled': loan.numberOfUnits !== null }"
                      class="form-control" placeholder="1" type="number" min="1" max="4"/>
              </div>
            </div>
            <div class="row mb-3" v-if="!loan.isReferral">
              <div class="form-group col-md-6">
                <label aria-label="fieldPropertyType">Property Type</label>
                <multiselect id="fieldPropertyType" v-model="loan.propertyType"
                            :allow-empty="false"
                            :class="{ selected: loan.propertyType !== null }"
                            :close-on-select="true"
                            :options="formData.propertyTypes"
                            :searchable="false"
                            :show-labels="false"
                            placeholder="Select property type">
                </multiselect>
              </div>
              <div class="form-group col-md-6" v-if="showAttachmentType">
                <label aria-label="fieldPropertyType">Attachment Type</label>
                <multiselect id="fieldAttachmentType" v-model="loan.attachmentType"
                            :allow-empty="false"
                            :class="{ selected: loan.attachmentType !== null }"
                            :close-on-select="true"
                            :options="formData.attachmentTypes"
                            :searchable="false"
                            :show-labels="false"
                            placeholder="Select property type">
                </multiselect>
              </div>
            </div>

            <div class="row">
              <div class="form-group mb-3">
                <label for="fieldRate">Rate</label>
                <div class="input-with-icon mb-2">
                  <span class="icon">%</span>
                  <input id="fieldRate"
                        v-model.number="loan.rate"
                        :class="{'input-filled': loan.rate !== null}"
                        class="form-control"
                        placeholder="2.875" step=".001" type="number">
                </div>
              </div>
            </div>

            <div class="row mb-3" v-if="loan.purpose === 'Purchase'">
              <div class="form-group col-md-6">
                <label for="fieldRate">Close of Escrow Date</label>
                <input id="fieldRate" v-model="loan.closeOfEscrowDate"
                       :class="{ 'input-filled': loan.closeOfEscrowDate !== null }"
                       class="form-control"
                       type="date"/>
              </div>
              <div class="form-group col-md-6">
                <label for="fieldRate">Appraisal Contingency Date</label>
                <input id="fieldRate" v-model="loan.appraisalContingencyDate"
                       :class="{ 'input-filled': loan.appraisalContingencyDate !== null }"
                       class="form-control"
                       type="date"/>
              </div>
            </div>

            <div class="row mb-3" v-if="loan.purpose === 'Purchase'">
              <div class="form-group col-md-6">
                <label for="fieldRate">Approval Contingency Date</label>
                <input id="fieldRate" v-model="loan.approvalContingencyDate"
                       :class="{ 'input-filled': loan.approvalContingencyDate !== null }"
                       class="form-control"
                       type="date"/>
              </div>
              <div class="form-group col-md-6">
                <label for="fieldRate">Inspection Contingency Date</label>
                <input id="fieldRate" v-model="loan.inspectionContingencyDate"
                       :class="{ 'input-filled': loan.inspectionContingencyDate !== null }"
                       class="form-control"
                       type="date"/>
              </div>
            </div>

            <div class="row">
              <div class="form-group mb-3" v-if="loan.enhancedProcessing">
                <label aria-label="fieldLoanProduct">Are we locking or floating?</label>
                <multiselect id="fieldRateType" v-model="loan.discloseRateType"
                             :allow-empty="false"
                             :class="{ selected: loan.discloseRateType !== null }"
                             :close-on-select="true"
                             :options="formData.discloseRateTypes"
                             :searchable="false"
                             :show-labels="false" label="label" track-by="value">
                </multiselect>
              </div>
            </div>

            <div class="row">
              <div class="form-group mb-3">
                <label for="fieldLendingPadNumber">LendingPad Number</label>
                <input id="fieldLendingPadNumber" v-model="loan.lendingpadNumber"
                      :class="{ 'input-filled': loan.lendingpadNumber !== null }"
                      class="form-control" placeholder="LendingPad Number (Optional)" type="text"/>
              </div>
            </div>

            <div class="row mb-4" v-if="ui.lenderOfReverse">
              <div class="form-group">
                <label aria-label="fieldReverseMortgageType">Reverse Mortgage Type</label>
                <multiselect id="fieldReverseMortgageType" class="full-width" placeholder="Select Mortgage Type"
                            v-model="loan.reverseMortgageType" :options="formData.mortgageTypeOptions"
                            :class="{selected: loan.reverseMortgageType !== null}"
                            :show-labels="false" :searchable="false"
                            :allow-empty="false" :close-on-select="true">
                </multiselect>
              </div>
              <div class="form-group">
                <label aria-label="fieldMortgagePurposeOptions">Reverse Mortgage Purpose</label>
                <multiselect id="fieldMortgagePurposeOptions" placeholder="Select Mortgage Purpose" class="full-width"
                            v-model="loan.reverseMortgagePurpose" :options="formData.mortgagePurposeOptions"
                            :class="{selected: loan.reverseMortgagePurpose !== null}"
                            :allow-empty="false" :show-labels="false" :searchable="false">
                </multiselect>
              </div>
            </div>

            <div class="row">
              <div class="form-group mb-3">
                <label for="fieldLienPosition">Lien Position</label>
                <multiselect id="fieldLienPosition"
                            v-model="loan.lienPosition"
                            :allow-empty="false"
                            :class="{selected: loan.lienPosition !== null}"
                            :close-on-select="true"
                            :options="formData.lienPositionOptions"
                            :searchable="false"
                            :show-labels="false"
                            placeholder="Select Lien Position">
                </multiselect>
              </div>
              <div class="form-group col-6" v-if="ui.lenderHasTagSecond">
                <label>Is this a second mortgage</label>
                <div class="mb-3">
                  <div class="d-flex">
                    <span :class="{ active: loan.lienPosition === 'Subordinate' }" class="check-btn small"
                          @click="loan.lienPosition = 'Subordinate'">YES</span>
                    <span :class="{ active: loan.lienPosition === 'First' }" class="check-btn small"
                          @click="loan.lienPosition = 'First'">NO</span>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="ui.isHighCostMortgage">
              <label>Is this a High-Cost Mortgage?</label>
              <div class="mb-3">
                <div class="d-flex">
                  <span :class="{ active: loan.isHighCostMortgage === true }" class="check-btn"
                        @click="loan.isHighCostMortgage = true">Yes</span>
                  <span :class="{ active: loan.isHighCostMortgage === false }" class="check-btn"
                        @click="loan.isHighCostMortgage = false">No</span>
                </div>
              </div>
            </div>

            <div class="form-group mb-3">
              <label for="fieldMiStatus">Is there MI?</label>
              <multiselect v-model="loan.miStatus"
                          placeholder="MI Status"
                          id="fieldMiStatus"
                          :options="formData.miStatuses"
                          :class="{selected: loan.miStatuses !== null}"
                          :searchable="false"
                          :close-on-select="true"
                          :allow-empty="false"
                          :show-labels="false">
              </multiselect>
            </div>

            <div v-if="loan.lenderType === 'Broker'" class="form-group mb-3">
              <label for="fieldCompensationType">Compensation Type</label>
              <multiselect v-model="loan.compensationType"
                          placeholder="Compensation Type"
                          id="fieldCompensationType"
                          :options="formData.compensationTypeOptions"
                          :class="{selected: loan.compensationType !== null}"
                          :searchable="false"
                          :close-on-select="true"
                          :allow-empty="false"
                          :show-labels="false">
              </multiselect>
            </div>
            <div v-if="loan.lenderType === 'Broker'" class="form-group mb-3 full-width">
              <label for="fieldWaivingUnderwriting">Are we waiving Underwriting? Is the fee built into the
                pricing?</label>
              <multiselect v-model="loan.waivingUnderwriting"
                          id="fieldWaivingUnderwriting"
                          track-by="value"
                          label="label"
                          :options="formData.waivingUnderwritingOptions"
                          :class="{selected: loan.waivingUnderwriting !== null}"
                          :searchable="false"
                          :close-on-select="true"
                          :allow-empty="false"
                          :show-labels="false">
              </multiselect>
            </div>
            <div class="form-group full-width mb-3"
                v-if="loan.waivedStatus === 'YES/Included in Payment'">
              <label for="fieldTaxesMonthsToCollect">How many months of taxes to collect?</label>
              <input
                v-model.number="loan.taxesMonthsToCollect"
                class="form-control small-width"
                id="fieldTaxesMonthsToCollect"
                :class="{'input-filled': loan.taxesMonthsToCollect !== null}"
                type="number"
                placeholder="1">
            </div>
            <div class="form-group full-width mb-3" v-if="loan.waivedStatus === 'YES/Included in Payment'">
              <label for="fieldInsuranceMonthsToCollect">How many months of insurance to collect?</label>
              <input
                v-model.number="loan.insuranceMonthsToCollect"
                :class="{'input-filled': loan.insuranceMonthsToCollect !== null}"
                class="form-control small-width"
                id="fieldInsuranceMonthsToCollect"
                type="number"
                placeholder="1">
            </div>
            <div class="form-group full-width mb-3">
              <label for="fieldDaysOfInterest">How many days of interest do we need to collect?</label>
              <input
                v-model.number="loan.daysOfInterest"
                class="form-control small-width"
                :class="{'input-filled': loan.daysOfInterest !== null}"
                id="fieldDaysOfInterest"
                type="number"
                placeholder="1">
            </div>
            <div class="form-group full-width mb-3" v-if="loan.purpose !== 'Purchase'">
              <label for="fieldCashoutAmount">Cashout Amount (Leave blank if Rate/Term)</label>
              <div class="input-with-icon form-group mb-2">
                <span class="icon">
                  $
                </span>
                <input id="fieldCashoutAmount"
                      v-model.number="loan.cashoutAmount"
                      :class="{'input-filled': loan.cashoutAmount !== null}"
                      class="form-control"
                      placeholder="15000"
                      type="number"
                      step="0.01">
              </div>
            </div>
            <div class="form-group mb-3" v-if="loan.purpose !== 'Purchase'">
              <label for="fieldLoanNumber">Short to Close?</label>
              <input v-model="loan.shortToClose"
                    class="form-control"
                    :class="{'input-filled': loan.shortToClose !== null}"
                    id="fieldShortToClose" type="text">
            </div>

            <div class="form-group mb-3" v-if="ui.selectedWorkflow.value">
              <label for="fieldEstimatedClosingDate">Estimated Closing Date</label>
              <input id="fieldEstimatedClosingDate" v-model="loan.estimatedClosingDate"
                    :class="{ 'input-filled': loan.estimatedClosingDate !== null }"
                    class="form-control" type="date"/>
            </div>
            <div class="form-group full-width mb-3">
              <label for="fieldAppraisalPayer">Who Pays for Appraisal?</label>
              <multiselect v-model="loan.appraisalPayer"
                           placeholder="Who Pays for Appraisal?"
                           id="fieldAppraisalPayer"
                           class="full-width"
                           :options="formData.appraisalPayerOptions"
                           :class="{selected: loan.appraisalPayer !== null}"
                           :searchable="false"
                           :close-on-select="true"
                           :allow-empty="false"
                           :show-labels="false">
              </multiselect>
            </div>
            <div class="form-group full-width mb-3">
              <label for="fieldAppraisalNote">Appraisal Note </label>
              <input
                type="text"
                id="fieldAppraisalNote"
                v-model="loan.appraisalNote"
                :class="{'input-filled': loan.appraisalNote !== null}"
                maxlength="255"
                placeholder="Appraisal Note"
                class="form-control">
            </div>
            <div class="form-group full-width mb-3">
              <label for="source">Where did the deal come from?</label>
              <multiselect id="source"
                           v-model="loan.source"
                           :allow-empty="false"
                           :class="{selected: loan.source !== null}"
                           :close-on-select="true"
                           :options="formData.sourceOptions"
                           :searchable="false"
                           :show-labels="false"
                           class="full-width">
              </multiselect>
            </div>
            <div class="form-group full-width mb-3" v-if="loan.source === 'Other'">
              <label for="fieldBorrowerMotivation">Please Describe “Other”</label>
              <textarea
                id="fieldBorrowerMotivation"
                v-model="loan.sourceOtherDescription"
                :class="{ 'input-filled': loan.sourceOtherDescription !== null }"
                class="form-control"
                rows="4">
              </textarea>
            </div>
            <div class="form-group full-width mb-3" v-if="!loan.isReferral">
              <label for="LO">Loan Officer of Record</label>
              <multiselect id="LO"
                           v-model="loan.LOofRecord"
                           :allow-empty="false"
                           :class="{selected: loan.LOofRecord !== null}"
                           :close-on-select="true"
                           :options="formData.agents"
                           :searchable="true"
                           :show-labels="false" track-by="value" label="label"
                           class="full-width" placeholder="Select agent">
              </multiselect>
            </div>
            <div class="form-group mt-3 other-options">
              <label>Other Options</label>
              <div class="form-check" :class="{active: loan.isHeroLoan === true}">
                <input v-model="loan.isHeroLoan" class="form-check-input" type="checkbox" id="fieldIsHeroLoan">
                <label class="form-check-label" for="fieldIsHeroLoan">HERO Loan</label>
              </div>
              <div class="form-check" :class="{active: loan.isSubordination === true}">
                <input v-model="loan.isSubordination" class="form-check-input" type="checkbox"
                       id="fieldIsSubordination">
                <label class="form-check-label" for="fieldIsSubordination">Subordination</label>
              </div>
              <div class="form-check" :class="{active: loan.isSolarPanels === true}">
                <input v-model="loan.isSolarPanels" class="form-check-input" type="checkbox" id="fieldIsSolarPanels">
                <label class="form-check-label" for="fieldIsSolarPanels">Solar Panels</label>
              </div>
              <div class="form-check" :class="{active: loan.eVerifyIncome === true}">
                <input v-model="loan.eVerifyIncome" class="form-check-input" type="checkbox" id="fieldEVerifyIncome">
                <label class="form-check-label" for="fieldEVerifyIncome">E-Verify Income</label>
              </div>
            </div>

            <!-- NOTE: For Credit login username and password -->
            <template v-if="ui.selectedWorkflow.value">
                <div class="form-group full-width other-options mb-3">
                    <label for="creditlogin">
                        Credit login
                        <img alt="preview"
                             v-tippy="{ placement : 'right',  arrow: true }"
                             content="Needed so SA can re issue credit at the lender"
                             src="@/assets/icons/icon-info.svg"
                        >
                    </label>
                    <div class="form-check">
                        <input id="alreadyhave-details" class="form-check-input" type="checkbox" v-model="loan.alreadyHasCreditLogin" />
                        <label class="form-check-label" for="alreadyhave-details">Assistant already has my credit credentials.</label>
                    </div>
                    <template v-if="!loan.alreadyHasCreditLogin">
                        <input
                            v-model="loan.creditLoginUserName"
                            :class="{ 'input-filled': loan.creditLoginUserName !== null }"
                            class="form-control mb-3"
                            type="text"
                            placeholder="Username"
                        />
                        <div class="input-with-icon icon-right">
                            <input
                                v-model="loan.creditLoginPassword"
                                :class="{ 'input-filled': loan.creditLoginPassword !== null }"
                                class="form-control"
                                :type="ui.showPasswordForClientLogin ? 'text' : 'password'"
                                placeholder="Password"
                            />
                            <img class="icon" alt="preview" src="@/assets/icons/icon-Show password.svg" @click="ui.showPasswordForClientLogin = !ui.showPasswordForClientLogin" />
                        </div>
                    </template>
                </div>
            </template>
            <ValidationErrorsList :isError="ui.form.isError" :error="ui.form.error" :errors="ui.form.errors"/>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-start">
          <button type="submit" class="btn bg-green submit">
            <span v-if="ui.form.loading"
                  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span v-if="!ui.form.loading">Save</span>
          </button>
          <button type="reset" class="btn btn-cancel" @click="$emit('close')">
            Discard
          </button>
        </div>
      </form>
    </div>
    <transition name="fade">
      <corr-pricer-modal v-if="ui.showCorrPricerModal"
                         :borrower-full-name="borrowerFullName"
                         :loan-amount="loan.amount"
                         :credit-score="loan.borrower.creditScore"
                         :state="loan.propertyState"
                         :property-type="loan.propertyType"
                         :attachment-type="loan.attachmentType"
                         :property-value="loan.propertyValue"
                         :property-use="loan.occupancy"
                         :purpose="loan.purpose"
                         :property-zip="loan.zip"
                         @submit-proposition="selectCorrPricing"
                         @close="ui.showCorrPricerModal = false"/>
    </transition>

    <transition name="fade">
      <NewRealtorModal v-if="ui.realtorModal"
                       @close="closeRealtorModal">
      </NewRealtorModal>
    </transition>
    <transition name="fade">
      <NewListingAgentModal v-if="ui.listingAgentModal"
                            @close="closeListingAgentModal">
      </NewListingAgentModal>
    </transition>
    <transition name="fade">
      <NewBuyingAgentModal v-if="ui.buyingAgentModal" @close="closeBuyingAgentModal">

      </NewBuyingAgentModal>
    </transition>
    <transition name="fade">
      <assign-realtors-modal :listing-agents="formData.listingAgents" :buying-agents="formData.buyingAgents" :listing-agent="loan.listingAgents"
                             v-if="ui.showAssignRealtorsModal" @close="closeAssignRealtorsModal" :buying-agent="loan.buyingAgents" />
    </transition>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import ValidationErrorsList from "../../components/form/ValidationErrorsList";
import ModalSpinner from "../../components/modal/ModalSpinner";
import PerfectScrollbar from 'perfect-scrollbar'
import FileFieldNew from "../../components/FileFieldNew";
import CorrPricerModal from "./modals/CorrPricerModal";
import NewRealtorModal from "@/views/loans/modals/NewRealtorModal.vue";
import NewListingAgentModal from "./modals/NewLIstingAgentModal.vue";
import NewBuyingAgentModal from "./modals/NewBuyingAgentModal.vue";
import AssignRealtorsModal from "./modals/AssignRealtorsModal.vue";

export default {
  name: "EditOriginationModal",
  components: {
    AssignRealtorsModal,
    NewBuyingAgentModal,
    NewListingAgentModal,
    FileFieldNew, ModalSpinner, ValidationErrorsList, Multiselect, CorrPricerModal, NewRealtorModal},
  props: {
    loanId: Number
  },
  data() {
    return {
      loan: {
        id: null,
        borrower: {
          firstName: null,
          lastName: null,
          coFirstName: null,
          coLastName: null,
          creditScore: null,
          email: null,
          coEmail: null,
        },
        isRefi: true,
        productType: null,
        productAndTerm: null,
        amount: null,
        number: null,
        numberOfUnits: null,
        payingOffMortgagesNumber: null,
        rate: null,
        miStatus: null,
        lender: null,
        lenderType: null,
        waivedStatus: null,
        daysOfInterest: null,
        cashoutAmount: null,
        appraisalPayer: null,
        appraisalNote: null,
        aus: null,
        isHeroLoan: false,
        isSubordination: false,
        isSolarPanels: false,
        eVerifyIncome: false,
        antiSteeringLowestRateAndFee: null,
        antiSteeringHighestRateAndFee: null,
        otherNotes: null,
        feesForBorrower: null,
        vaExempt: null,
        compensationType: null,
        waivingUnderwriting: null,
        insuranceMonthsToCollect: null,
        taxesMonthsToCollect: null,
        processor: null,
        shortToClose: null,
        propertyState: null,
        occupancy: null,
        propertyType: null,
        attachmentType: null,
        source: null,
        alreadyHasCreditLogin: true,
        creditLoginUserName: null,
        creditLoginPassword: null,
        lendingpadNumber: null,
        estimatedClosingDate: null,
        realtor: null,
        listingAgents: [],
        buyingAgents: [],
      },
      stips: {
        received: [],
        uploaded: [],
      },
      formData: {
        vaTypes: [
          'None',
          'VA Cash Out Type I',
          'VA Cash Out Type II',
          'VA IRLL',
        ],
        vaUseTypes: [
          'Select One',
          'First Use',
          'Subsequent Use'
        ],
        loanTypes: [
          'Conventional',
          'Conventional Hi-Bal',
          'FHA',
          'VA',
          'Non-QM',
          'JUMBO',
          'USDA/FSA/FHS',
          'HELOC',
          'Fixed Lien',
          'DSCR/business purpose'
        ],
        realtors: [
          {id: 'new_realtor', fullName: '+ New Realtor'},
        ],
        listingAgents: [
          {id: 'new_agent', fullName: '+ New Agent'},
        ],
        buyingAgents: [
          {id: 'new_agent', fullName: '+ New Agent'},
        ],
        discloseRateTypes: [
          {value: 'lock', label: 'Lock immediately'},
          {value: 'float', label: 'Float'},
        ],
        workflows: [
          {value: false, label: 'I am disclosing and collecting stips (LO disclose)'},
          {value: true, label: 'Submission Assistant'}
        ],
        lenders: [], // [{value: 1, label: "UWM"}, ...]
        companies: [], // [{value: 1, label: "UWM"}, ...]
        mortgageTypeOptions: ['HECM-Standard', 'HECM-Saver', 'Proprietary/Other'],
        mortgagePurposeOptions: ['Home Purchase', 'Other'],
        lienPositionOptions: ['First', 'Second', 'Subordinate', 'Not Secured by Lien'],
        processors: [], // [{value: 1, label: "John Doe"}, ...]
        enhancedProcessors: [], // [{value: 1, label: "John Doe"}, ...]
        processorOptions: [],
        vestingOptions: ['Community Property', 'Joint Tenants', 'Sole'],
        states: [],
        agents: [],
        occupancies: [
          'Primary Residence',
          'Secondary Residence',
          'Investment Property',
          'Commercial',
          'Other'
        ],
        propertyTypes: [
          'Single Family Residence',
          '2-4 Unit Dwelling',
          'Condominium',
          'Modular',
          'Planned Unit Development',
          'Site Condo',
          'Manufactured Multi Wide',
          'Manufactured Single Wide',
          'Manufactured Condo Single Wide',
          'Manufactured PUD Single Wide',
          'Manufactured Condo Multi Wide',
          'Manufactured PUD Multi Wide',
          'Commercial Other'
        ],
        attachmentTypes: [
          'Attached',
          'Detached',
        ],
        miStatuses: ['No', 'Yes, Lender Paid', 'Yes, Borrower Paid', 'Yes, FHA', 'Yes, Other'],
        lenderTypes: ['Broker', 'Correspondent'],
        waivedStatuses: ['Waived/Not Included in Payment', 'YES/Included in Payment', 'Yes, impound taxes only', 'Yes, impound insurance only'],
        appraisalPayerOptions: [
          'Borrower is paying',
          'Clear Mortgage paid and reimbursed',
          'Clear Mortgage paid and its PROMO',
          'Appraisal Waived',
          'Unsure still',
          'Hold appraisal for now',
        ],
        sourceOptions: [],
        ausOptions: ['DU', 'LP', 'Doesn\'t matter', 'N/A'],
        vaExemptOptions: [{label: 'Exempt', value: true}, {label: 'Not Exempt (Financed)', value: false}],
        compensationTypeOptions: ['Borrower Paid', 'Lender Paid'],
        waivingUnderwritingOptions: [{label: 'Yes', value: true}, {label: 'No', value: false}],
      },
      ui: {
        loan: {loading: false, isError: false},
        form: {loading: false, isError: false, isSuccess: false, error: '', errors: [], addHOA: false},
        selectedWorkflow: {value: false, label: 'I am disclosing and collecting stips (LO disclose)'},
        showCorrPricerModal: false,
        showPasswordForClientLogin: false,
        realtorModal: false,
        listingAgentModal: false,
        buyingAgentModal: false,
        showAssignRealtorsModal: false,
      }
    }
  },
  methods: {
    selectCorrPricing(val, formData) {
      let note = '';
      let isCredit = val.option.final_price < 0
      let finalPrice = Math.abs(val.option.final_price)

      note += `You are offering a ${val.option.term}-year fixed, for ${val.optionIdx}% with a `
      if (isCredit) {
        note += `(${this.convertToCurrency(finalPrice)}) credit.\n\n`
      } else {
        note += `${this.convertToCurrency(finalPrice)} discount fee.\n\n`
      }

      note += 'Origination:\n'
      note += 'Underwriting Fee: $1,395.00\n'
      if (isCredit) {
        note += `Lender Credit: (${this.convertToCurrency(finalPrice)})\n`
      } else {
        note += `Discount Fee: ${this.convertToCurrency(finalPrice)}\n`
      }

      let adjustedOriginationCharge = val.option.final_price + 1395;
      let adjustedOriginationChargeFormatted = this.convertToCurrency(Math.abs(adjustedOriginationCharge));
      if (isCredit) {
        if (adjustedOriginationCharge < 0) {
          note += `Adjusted Origination Charge: (${adjustedOriginationChargeFormatted})\n\n`
        } else {
          note += `Adjusted Origination Charge: ${adjustedOriginationChargeFormatted}\n\n`
        }
      } else {
        note += `Adjusted Origination Charge: ${this.convertToCurrency(finalPrice + 1395)}\n\n`
      }

      if (isCredit) {
        note += 'The fees will look like this on the Loan Estimate:\n\n'
      }

      note += 'Section A:\n'
      if (!isCredit) {
        let formattedFinalPrice = this.convertToCurrency(finalPrice)
        let priceAsNumber = Number.parseFloat((finalPrice / formData.loanAmount) * 100).toFixed(5)
        note += `Correspondent Discount Fee: ${formattedFinalPrice} (${priceAsNumber})\n`
      }
      note += 'Underwriting Fee: $1,395.00\n'
      note += 'Wire Fee: $47.00\n'
      if (isCredit) {
        note += `Correspondent Lender Credit: ${this.convertToCurrency(finalPrice)}\n`
      }

      note += '\n\n'

      note += 'Section B:\n'
      note += 'Flood Certificate: $8\n'
      note += 'Tax Service: $85\n'

      if (this.isGranted('ROLE_SD_COMP_PLAN')) {
        note += 'Third Party Processing: $1990\n'
      }

      if (this.showAttachmentType) {
        note += 'HOA Fee: $250.00\n'
      }

      if (formData.loanType === 'VA') {
        note += 'Pest Inspection: $300.00\n'
        if (formData.state === 'TX') {
          note += 'Survey Fee: $600.00\n'
        }
      }

      // if (val.option.fundingFee > 0) {
      //   note += `Funding fee: ${this.convertToCurrency(val.option.fundingFee)}\n`
      // }

      note += '\n'

      note += 'Section C + D:\n'
      note += 'Third Party fees\n\n'

      this.loan.propertyState = formData.state
      this.loan.zip = formData.zip
      this.loan.county = formData.county
      this.loan.purpose = formData.loanPurpose
      this.loan.propertyType = formData.propertyType
      this.loan.propertyValue = formData.propertyValue
      this.loan.numberOfUnits = formData.numberOfUnits
      this.loan.attachmentType = formData.propertyAttachmentType
      this.loan.occupancy = formData.propertyUse
      this.loan.amount = formData.loanAmount
      this.loan.feesForBorrower = note
      this.loan.rate = val.optionIdx;
      this.loan.productAndTerm = val.option.term
      this.ui.showCorrPricerModal = false;
    },
    submitForm(e) {
      e.preventDefault();
      this.ui.form.errors = [];
      this.ui.form.error = '';
      this.ui.form.isError = false;
      this.ui.form.loading = true;

      let formData = new FormData()

      for (let file of this.stips.uploaded) {
        formData.append(`stips[]`, file)
      }

      formData.append('loan', JSON.stringify({
        ...this.loan,
        lender: this.loan.lender?.value ?? null,
        realtor: this.loan.realtor?.id ?? null,
        LOofRecord: this.loan.LOofRecord?.value ?? null,
        processor: this.loan.processor?.value ?? null,
        preferredTitleCompany: this.loan.preferredTitleCompany?.value ?? null,
        isEnhancedProcessing: this.loan.enhancedProcessing,
        waivingUnderwriting: this.loan.processor?.value ?? null,
        vaExempt: this.loan.vaExempt?.value ?? null,
        propertyState: this.loan.isReferral ? this.loan.state?.value : this.loan.propertyState,
        isReferralLoan: this.loan.isReferral,
        discloseRateType: this.loan.enhancedProcessing
          ? (this.loan.discloseRateType ? this.loan.discloseRateType.value : null)
          : null,
        hoaName: this.ui.form.addHOA === false ? null : this.loan.hoaName,
        hoaPhone: this.ui.form.addHOA === false ? null : this.loan.hoaPhone,
        hoaEmail: this.ui.form.addHOA === false ? null : this.loan.hoaEmail,
      }))

      this.$http.post(`/api/v1/loans/edit/${this.loanId}`, formData).then(() => {
        this.stips.uploaded = [];
        this.ui.form.isError = false;
        this.ui.form.isSuccess = true;
        this.pushAlert('success', 'Information successfully saved!')
        setTimeout(() => {
          this.ui.form.isSuccess = false
          this.$emit('loanUpdated')
          this.$emit('close')
        }, 1500)
      }).catch((e) => {
        this.ui.form.isSuccess = false;
        this.ui.form.isError = true;
        this.pushAlert('error', e.response.data.message)
        if (e.response.data.errors) {
          this.ui.form.errors = e.response.data.errors;
        }

        this.ui.form.error = this.getFirstErrorFromResponse(e.response);

        let container = this.$el.querySelector("#edit-origination-form-modal-block");

        this.$nextTick(() => {
          container.scrollTop = 1000 + container.scrollHeight;
        });

      })
        .finally(() => {
          this.ui.form.loading = false
        })
    },
    loadLoan() {
      this.ui.loan.loading = true;
      this.$http.get(`/api/v1/loans/edit/${this.loanId}`)
        .then(res => {
          this.loan = res.data.loan
          this.stips.received = res.data.stips
          this.loan.waivingUnderwriting = {
            label: this.loan.waivingUnderwriting ? 'Yes' : 'No',
            value: this.loan.waivingUnderwriting,
          }
          this.loan.vaExempt = {
            label: this.loan.vaExempt ? 'Exempt' : 'Not Exempt (Financed)',
            value: this.loan.vaExempt,
          }
          this.formData.lenders = res.data.lenders
          this.formData.realtors.push(...res.data.realtors)
          this.formData.listingAgents.push(...res.data.listingAgents)
          this.formData.buyingAgents.push(...res.data.buyingAgents)
          this.formData.companies = res.data.escrowCompanies
          this.formData.agents = res.data.agents
          this.formData.processors = res.data.processors
          this.formData.enhancedProcessors = res.data.enhancedProcessors
          this.formData.processorOptions = this.loan.enhancedProcessing
            ? this.formData.enhancedProcessors
            : this.formData.processors
          this.ui.selectedWorkflow = this.loan.enhancedProcessing ? this.formData.workflows[1] : this.formData.workflows[0]
          this.formData.states = this.loan.isReferral ? Object.values(res.data.states) : res.data.states
          this.formData.sourceOptions = res.data.sourceOptions
          this.checkLenderTags(this.loan.lender)

          this.ui.form.addHOA = this.loan.hoaName || this.loan.hoaPhone || this.loan.hoaEmail

          this.ui.loan.isError = false
        })
        .catch(() => {
          this.ui.loan.isError = true
        }).finally(() => {
        this.ui.loan.loading = false
      })
    },
    submitStips(files) {
      this.stips.uploaded = this.stips.uploaded.concat(files)
    },
    deleteStip(id) {
      if (confirm("Are you sure?")) {
        this.$http.delete(`/api/v1/stips/delete/${id}`)
        this.stips.received = this.stips.received.filter((stip) => stip.id !== id)
      }
    },
    deleteUploadedStip(name) {
      this.stips.uploaded = this.stips.uploaded.filter((stip) => stip.name !== name)
    },
    checkLenderTags(lender) {
      this.ui.lenderOfReverse = false
      this.ui.lenderHasTagSecond = false
      this.ui.isHighCostMortgage = false
      this.loan.lienPosition = this.formData.lienPositionOptions[0]

      lender.tags.map((el) => {
        if (el.name === 'Reverse') {
          this.ui.lenderOfReverse = true
        }

        if (el.name === 'Second') {
          this.ui.lenderHasTagSecond = true
        }

        if (el.name === 'Non-QM' || el.name === 'Non QM') {
          this.ui.isHighCostMortgage = true
        }
      })
    },
    toggleProcessors(selectedOption) {
      if (selectedOption) {
        this.loan.enhancedProcessing = selectedOption.value
      }

      this.formData.processorOptions = this.loan.enhancedProcessing
        ? this.formData.enhancedProcessors
        : this.formData.processors
      this.loan.processor = null
    },
    checkLenderType(val) {
      if (val === 'Correspondent') {
        this.loan.lender = this.formData.lenders.filter((el) => {
          if (el.label === 'UWM') {
            return el;
          }
        })[0]
      } else {
        this.loan.lender = null
      }
    },
    selectState(option) {
      this.loan.LOofRecord = option.agents[0]
    },
    selectRealtor(realtor) {
      if (realtor.id === 'new_realtor') { // Open modal to create a new realtor
        this.ui.realtorModal = true;
        this.loan.realtor = null
      }
    },
    selectListingAgent(agent) {
      if (agent.id === 'new_agent') { // Open modal to create a new realtor
        this.ui.listingAgentModal = true;
        this.loan.listingAgent = []
      }
    },
    selectBuyingAgent(agent) {
      if (agent.id === 'new_agent') { // Open modal to create a new realtor
        this.ui.buyingAgentModal = true;
        this.loan.buyingAgent = []
      }
    },
    closeListingAgentModal(realtor = null) {
      if (realtor) {
        let option = {
          id: realtor.id,
          fullName: realtor.fullName
        };
        this.formData.listingAgents.push(option);
        this.loan.listingAgents.push(option);
      } else {
        this.loan.listingAgent = null
      }

      this.ui.listingAgentModal = false;
    },
    closeBuyingAgentModal(agent = null) {
      if (agent) {
        let option = {
          id: agent.id,
          fullName: agent.fullName
        };
        this.formData.buyingAgents.push(option);
        this.loan.buyingAgents.push(option)
      } else {
        this.loan.buyingAgent = null
      }

      this.ui.buyingAgentModal = false;
    },
    closeAssignRealtorsModal(data) {
      const listingAgents = []
      const buyingAgents = []

      if (!data) {
        this.ui.showAssignRealtorsModal = false
        return
      }

      if (data.listingAgent)  listingAgents.push(...data.listingAgent)

      if (data.listingAgentTC)  listingAgents.push(data.listingAgentTC)

      if (data.buyingAgent) buyingAgents.push(...data.buyingAgent)

      if (data.buyingAgentTC) buyingAgents.push(data.buyingAgentTC)

      this.loan.listingAgents = listingAgents
      this.loan.buyingAgents = buyingAgents

      this.ui.showAssignRealtorsModal = false
    },
    closeRealtorModal(realtor = null) {
      if (realtor) {
        let option = {
          id: realtor.id,
          fullName: `${realtor.fullName}`
        };
        this.formData.realtors.push(option);
        this.loan.realtor = option
      } else {
        this.loan.realtor = null
      }

      this.ui.realtorModal = false;
    },
    removeListingAgent(id = null) {
      this.loan.listingAgents = this.loan.listingAgents.filter(agent => agent.id !== id)
    },
    removeBuyingAgent(id = null) {
      this.loan.buyingAgents = this.loan.buyingAgents.filter(agent => agent.id !== id)
    },
  },
  computed: {
    loanNumberPlaceHolder() {
      if (this.loan.lenderType === this.formData.lenderTypes[1]) {
          return '';
      }
      return 'Optional';
    },
    borrowerFullName() {
      if (this.loan !== undefined) {
        return this.loan.borrower.firstName !== null ? this.loan.borrower.firstName : '' + ' ' + this.loan.borrower.lastName !== null ? this.loan.borrower.lastName : '';
      }
      return '';
    },
    showAttachmentType() {
      let type = this.loan.propertyType;
      return (
        type === "Condominium" ||
        type === "Site Condo" ||
        type === "Manufactured Condo Single Wide" ||
        type === "Manufactured Condo Multi Wide"
      );
    }
  },
  created() {
    this.loadLoan()
  },
  mounted() {
    const scrollbar = document.getElementById('edit-origination-form-modal-block');
    this.ui.ps = new PerfectScrollbar(scrollbar, {
      useBothWheelAxes: false,
      suppressScrollX: true
    });
  }

}
</script>
<style lang="scss" scoped>
#edit-origination-form-modal-block {
  position: relative;
  overflow: hidden;
  height: calc(100% - 50px);
}

hr {
  color: #E7E8E8;
}

.other-options {
  .form-check {
    border: 1px solid rgba(231, 232, 232, 1);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
    height: 43px;
    width: fit-content;
    padding: 0 12px;
    margin-bottom: 12px;

    &.active {
      border: 1px solid rgba(1, 122, 255, 1);
    }

    input {
      margin: 0 10px 0 0;

      &:checked {
        background-color: #0d6efd;
        border-color: #0d6efd;
      }
    }

    label {
      color: rgba(0, 0, 0, 1);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      font-weight: normal;
      white-space: nowrap;
      margin: 0;
    }
  }
}

.form-check {
  input.form-check-input {
    height: 18px;
    width: 18px;
    max-width: 18px;
    margin-right: 10px;
  }
}

.modal-body-container::v-deep .multiselect {
  width: auto;

  &.selected {
    .multiselect__tags {
      background-color: #f3f3f4;
    }

    .multiselect__single {
      background-color: #f3f3f4;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
    }

    input {
      background-color: #f3f3f4;
    }
  }

  .multiselect__tag {
    margin-bottom: 0 !important;
    margin-top: 5px !important;
  }

  &.multiselect--active {
    .multiselect__tags {
      transition: all 0.3s;
      border: 1px solid rgba(38, 111, 229, 0.5);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__tags {
    display: flex;
    align-items: center;
    padding: 12px 40px 12px 15px;
    transition: all 0.3s;

    &:hover {
      border: 1px solid rgba(38, 111, 229, 0.2);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__single {
    padding: 0;
  }

  &__select {
    &:before {
      top: 17%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }

  &__single {
    color: rgba(0, 0, 0, 0.46);
    margin-bottom: 0;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }

  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all 0.3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }

    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }

  &__placeholder {
    padding: 0;
    margin: 0;
  }
  &__input {
    padding: 0;
    margin: 0;
  }
}

.borrower-from {
  opacity: 0.46;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}

.borrower-name,
.borrower-conventional {
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  font-weight: normal;
}

.borrower-conventional {
  margin-left: 12px;
  opacity: 0.7;
}

.borrower-info {
  margin-left: 24px;
}

.modal-footer {
  width: 100%;

  .submit {
    min-width: 120px;
  }
}

.w-76 {
  width: 76% !important;
  max-width: 76% !important;
}

.processor-details {
  border: 1px solid #e7e8e8;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  margin-top: 5px;

  img {
    height: 52px;
    border-radius: 50px;
    margin-right: 10px;
  }
}
</style>
